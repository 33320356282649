<template>
	<div id="webHeader">
		<h1>绿洲CHAT医生第二大脑</h1>
	</div>
</template>

<script>
	export default {
		name: 'webHeader',
		props: {
			msg: String
		}
	}
</script>

<style scoped lang="less">
	#webHeader {
		width: 100vw;
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #343549;

		h1 {
			font-size: 24px;
			color: #fff;
		}
	}
</style>